import React, { useState, useContext, useEffect, useRef } from 'react';
import { 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Typography,
  Box,
  TableSortLabel,
  Button,
  Chip,
  CircularProgress,
  Tooltip
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import axios from "axios";
import { ENDPOINT } from "../../../config/config";
import { UserContextType } from "../../../@types/UserContext";
import UserContext from "../../../context/user/UserContext";
import { styled } from '@mui/material/styles';

interface MTBEntry {
  htsCode: string;
  mtbNumber: string;
  oldDutyRate: string;
  newDutyRate: string;
  savings: number;
  description: string;
  status: 'Active' | 'Pending' | 'Expired';
  expirationDate: string;
  goodsValue: number;
}

const getStatusColor = (status: MTBEntry['status']) => {
  switch (status) {
    case 'Active':
      return 'success';
    case 'Pending':
      return 'warning';
    case 'Expired':
      return 'error';
    default:
      return 'default';
  }
};

interface MTBTabProps {
  dates: any;
  page: number;
  dutySpendFilter: string;
  pageSize: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

type SortField = 'htsCode' | 'mtbNumber' | 'oldDutyRate' | 'newDutyRate' | 'savings' | 'status' | 'expirationDate' | 'goodsValue';
type SortOrder = 'asc' | 'desc';

const DescriptionCell = ({ text }: { text: string }) => {
  const [hasOverflow, setHasOverflow] = useState(false);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (textRef.current) {
      const element = textRef.current;
      setHasOverflow(element.scrollHeight > element.clientHeight);
    }
  }, [text]);

  const Typography = styled('p')({
    maxWidth: '400px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    lineHeight: '1.2em',
    maxHeight: '3.6em',
    whiteSpace: 'normal',
    margin: 0,
    '&:hover': {
      cursor: hasOverflow ? 'pointer' : 'default',
      textDecoration: hasOverflow ? 'underline' : 'none'
    }
  });

  const content = (
    <Typography ref={textRef}>
      {text}
    </Typography>
  );

  return hasOverflow ? (
    <Tooltip title={text} placement="top-start">
      {content}
    </Tooltip>
  ) : content;
};

const MTBTab: React.FC<MTBTabProps> = ({
  dates,
  page,
  dutySpendFilter,
  pageSize,
  setPage
}) => {
  const [sortField, setSortField] = useState<SortField>('savings');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
  const [mtbData, setMtbData] = useState<MTBEntry[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const userContext = useContext(UserContext) as UserContextType;

  useEffect(() => {
    fetchMTBData();
  }, [dates, page, dutySpendFilter, pageSize]);

  const fetchMTBData = async () => {
    try {
      const startDate = dates?.startDate ?? "";
      const endDate = dates?.endDate ?? "";
      setLoading(true);

      const result = await axios.get(
        `${ENDPOINT}/dashboard-potential/savings/mtb?dateFrom=${startDate}&dateTo=${endDate}&pageNo=${page}&pageSize=${pageSize}`,
        {
          headers: {
            authorization: `Bearer ${userContext?.user?.token}`,
          },
        }
      );

      if (result?.data?.data) {
        setMtbData(result.data.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error('Error fetching MTB data:', err);
    }
  };

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('desc');
    }
  };

  const renderSortLabel = (field: SortField, label: string) => (
    <TableSortLabel
      active={sortField === field}
      direction={sortField === field ? sortOrder : 'desc'}
      onClick={() => handleSort(field)}
    >
      {label}
    </TableSortLabel>
  );

  const handleDownload = () => {
    const headers = ['HTS Code', 'MTB Number', 'Current Rate', 'MTB Rate', 'Potential Savings', 'Status', 'Expiration Date', 'Description'];
    const csvData = sortedData.map(row => [
      row.htsCode,
      row.mtbNumber,
      row.oldDutyRate,
      row.newDutyRate,
      `$${row.savings.toLocaleString()}`,
      row.status,
      row.expirationDate,
      row.description
    ]);

    const csvContent = [
      headers.join(','),
      ...csvData.map(row => row.map(cell => `"${cell}"`).join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'mtb_opportunities.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const sortedData = [...mtbData].sort((a, b) => {
    const multiplier = sortOrder === 'asc' ? 1 : -1;
    
    switch (sortField) {
      case 'htsCode':
        return multiplier * a.htsCode.localeCompare(b.htsCode);
      case 'mtbNumber':
        return multiplier * a.mtbNumber.localeCompare(b.mtbNumber);
      case 'oldDutyRate':
        return multiplier * a.oldDutyRate.localeCompare(b.oldDutyRate);
      case 'newDutyRate':
        return multiplier * a.newDutyRate.localeCompare(b.newDutyRate);
      case 'savings':
        return multiplier * (a.savings - b.savings);
      case 'status': {
        const statusOrder = { Active: 3, Pending: 2, Expired: 1 };
        return multiplier * (statusOrder[a.status] - statusOrder[b.status]);
      }
      case 'expirationDate':
        return multiplier * new Date(a.expirationDate).getTime() - new Date(b.expirationDate).getTime();
      case 'goodsValue':
        return multiplier * (a.goodsValue - b.goodsValue);
      default:
        return 0;
    }
  });

  return (
    <Box sx={{ 
      height: 'calc(100vh - 200px)',
      p: 3,
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Paper sx={{ 
        width: '100%', 
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Box sx={{ 
          p: 2, 
          borderBottom: '1px solid rgba(224, 224, 224, 1)',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Typography variant="h6">
            MTB Opportunities
          </Typography>
          {mtbData.length > 0 && (
            <Button
              variant="outlined"
              startIcon={<FileDownloadIcon />}
              onClick={handleDownload}
              sx={{ ml: 2 }}
            >
              Download CSV
            </Button>
          )}
        </Box>
        
        {loading ? (
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            height: '100%' 
          }}>
            <CircularProgress />
          </Box>
        ) : mtbData.length === 0 ? (
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            height: '100%',
            flexDirection: 'column',
            gap: 2
          }}>
            <Typography variant="h6" color="text.secondary">
              No Opportunities Available
            </Typography>
            <Typography variant="body1" color="text.secondary">
              There are currently no MTB opportunities that match your criteria.
            </Typography>
          </Box>
        ) : (
          <TableContainer sx={{ flexGrow: 1 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {renderSortLabel('htsCode', 'HTS Code')}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {renderSortLabel('mtbNumber', 'MTB Number')}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    Status
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {renderSortLabel('goodsValue', 'Goods Value')}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {renderSortLabel('oldDutyRate', 'Current Duty Rate')}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {renderSortLabel('newDutyRate', 'New Duty Rate')}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {renderSortLabel('savings', 'Potential Savings')}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    Description
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData.map((row, index) => (
                  <TableRow 
                    key={index}
                    sx={{ '&:nth-of-type(odd)': { backgroundColor: 'rgba(0, 0, 0, 0.02)' } }}
                  >
                    <TableCell>{row.htsCode}</TableCell>
                    <TableCell>{row.mtbNumber}</TableCell>
                    <TableCell>
                      <Tooltip title={`Expires: ${row.expirationDate}`} placement="top">
                        <Chip 
                          label={row.status}
                          color={getStatusColor(row.status)}
                          size="small"
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell>${row.goodsValue.toLocaleString()}</TableCell>
                    <TableCell>{row.oldDutyRate}</TableCell>
                    <TableCell>{row.newDutyRate}</TableCell>
                    <TableCell sx={{ 
                      color: 'success.main',
                      fontWeight: 'bold'
                    }}>
                      ${row.savings.toLocaleString()}
                    </TableCell>
                    <TableCell>
                      <DescriptionCell text={row.description} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </Box>
  );
};

export default MTBTab; 