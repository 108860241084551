import React, { useState, useContext, useEffect, useRef } from 'react';
import { 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Typography,
  Box,
  TableSortLabel,
  Button,
  CircularProgress,
  FormControlLabel,
  Switch,
  Chip,
  Tooltip
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import axios from "axios";
import { ENDPOINT } from "../../../config/config";
import { UserContextType } from "../../../@types/UserContext";
import UserContext from "../../../context/user/UserContext";
import styled from '@mui/material/styles/styled';

interface ExclusionEntry {
  htsCode: string;
  code99: string;
  oldDutyRate: string;
  newDutyRate: string;
  savings: number;
  description: string;
  active: boolean;
  goodsValue: number;
}

// Update the helper function to prevent negative rates
const generateDutyRates = () => {
  const baseRates = [20, 25, 30, 35, 40];
  const oldRate = baseRates[Math.floor(Math.random() * baseRates.length)];
  
  // Calculate maximum possible reduction to ensure new rate is positive
  const maxReduction = oldRate - 5; // Ensures at least 5% minimum new rate
  const possibleReductions = [7.5, 10, 15, 20, 25].filter(r => r < maxReduction);
  const reduction = possibleReductions[Math.floor(Math.random() * possibleReductions.length)];
  
  const newRate = oldRate - reduction;
  
  return {
    oldDutyRate: `${oldRate}%`,
    newDutyRate: `${newRate}%`
  };
};

// Update the test data with varied duty rates and update the interface
const testData: ExclusionEntry[] = [
  {
    htsCode: "8414.59.6590",
    code99: "9903.88.67",
    ...generateDutyRates(),
    savings: 125000,
    description: "Axial fans, each having a self-contained electric motor...",
    active: true,
    goodsValue: 100000
  },
  {
    htsCode: "8414.30.8060",
    code99: "9903.88.65",
    ...generateDutyRates(),
    savings: 87500,
    description: "Compressors for air conditioners, each having a self-contained electric motor...",
    active: true,
    goodsValue: 70000
  },
  {
    htsCode: "8501.10.4020",
    code99: "9903.88.66",
    ...generateDutyRates(),
    savings: 156000,
    description: "DC motors, of an output not exceeding 37.5 W, each valued over $2.75 but not over $3.50",
    active: true,
    goodsValue: 120000
  },
  {
    htsCode: "8504.40.9510",
    code99: "9903.88.65",
    ...generateDutyRates(),
    savings: 92400,
    description: "Power supplies suitable for physical incorporation into automatic data processing (ADP) machines or units thereof of heading 8471",
    active: true,
    goodsValue: 80000
  },
  {
    htsCode: "8536.69.8000",
    code99: "9903.88.67",
    ...generateDutyRates(),
    savings: 178500,
    description: "Electrical connectors, for a voltage not exceeding 1,000 V, each valued over $0.35 but not over $0.55",
    active: true,
    goodsValue: 150000
  },
  {
    htsCode: "8708.99.8180",
    code99: "9903.88.65",
    ...generateDutyRates(),
    savings: 245000,
    description: "Parts of suspension systems of motor vehicles designed for on-highway use",
    active: true,
    goodsValue: 200000
  },
  {
    htsCode: "8415.90.8085",
    code99: "9903.88.66",
    ...generateDutyRates(),
    savings: 167300,
    description: "Parts of air conditioning machines, each incorporating a motor-driven fan and elements for varying temperature and humidity",
    active: true,
    goodsValue: 140000
  },
  {
    htsCode: "8481.80.1085",
    code99: "9903.88.67",
    ...generateDutyRates(),
    savings: 198400,
    description: "Taps, cocks, valves and similar appliances for pipes, boiler shells, tanks, vats or the like, including pressure-reducing valves",
    active: true,
    goodsValue: 170000
  },
  {
    htsCode: "8507.60.0010",
    code99: "9903.88.65",
    ...generateDutyRates(),
    savings: 312600,
    description: "Lithium-ion batteries, rated at 10.8 V, designed for use with electronic games of heading 9504",
    active: true,
    goodsValue: 280000
  },
  {
    htsCode: "8544.42.9090",
    code99: "9903.88.66",
    ...generateDutyRates(),
    savings: 143700,
    description: "Electric conductors for a voltage not exceeding 1,000 V, fitted with connectors, each valued over $2",
    active: true,
    goodsValue: 120000
  },
  {
    htsCode: "8708.29.5060",
    code99: "9903.88.67",
    ...generateDutyRates(),
    savings: 276500,
    description: "Parts and accessories of bodies for motor vehicles, including cab components and body panels",
    active: true,
    goodsValue: 250000
  },
  {
    htsCode: "8537.10.9170",
    code99: "9903.88.65",
    ...generateDutyRates(),
    savings: 189200,
    description: "Control panels for electric motors, for a voltage not exceeding 1,000 V",
    active: true,
    goodsValue: 160000
  },
  {
    htsCode: "8543.70.9960",
    code99: "9903.88.66",
    ...generateDutyRates(),
    savings: 154800,
    description: "Electrical machines with translation or dictionary functions; flat panel displays",
    active: true,
    goodsValue: 130000
  },
  {
    htsCode: "8708.80.6590",
    code99: "9903.88.67",
    ...generateDutyRates(),
    savings: 234500,
    description: "Suspension shock absorbers for motor vehicles designed for on-highway use",
    active: true,
    goodsValue: 210000
  },
  {
    htsCode: "8482.10.5044",
    code99: "9903.88.65",
    ...generateDutyRates(),
    savings: 167800,
    description: "Ball bearings with integral shafts, each valued over $2 but not over $7",
    active: true,
    goodsValue: 150000
  },
  {
    htsCode: "8511.80.6000",
    code99: "9903.88.66",
    ...generateDutyRates(),
    savings: 145900,
    description: "Electrical ignition or starting equipment for internal combustion engines",
    active: true,
    goodsValue: 130000
  },
  {
    htsCode: "8536.50.9035",
    code99: "9903.88.67",
    ...generateDutyRates(),
    savings: 198700,
    description: "Electronic switches, for a voltage not exceeding 1,000 V, push-button type, push-pull type, snap-action type and slide type",
    active: true,
    goodsValue: 180000
  },
  {
    htsCode: "8708.94.7560",
    code99: "9903.88.65",
    ...generateDutyRates(),
    savings: 287400,
    description: "Steering wheels, steering columns and steering boxes for motor vehicles",
    active: true,
    goodsValue: 260000
  },
  {
    htsCode: "8512.20.4040",
    code99: "9903.88.66",
    ...generateDutyRates(),
    savings: 156800,
    description: "Electrical lighting equipment designed for motor vehicles",
    active: true,
    goodsValue: 140000
  },
  {
    htsCode: "8483.50.6000",
    code99: "9903.88.67",
    ...generateDutyRates(),
    savings: 178900,
    description: "Flywheels and pulleys, including pulley blocks, designed for use in motor vehicles",
    active: true,
    goodsValue: 160000
  }
];

interface ExclusionsTabProps {
  dates: any;
  page: number;
  dutySpendFilter: string;
  pageSize: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

type SortField = 'htsCode' | 'code99' | 'oldDutyRate' | 'newDutyRate' | 'savings' | 'goodsValue';
type SortOrder = 'asc' | 'desc';

const ExclusionsTab: React.FC<ExclusionsTabProps> = ({
  dates,
  page,
  dutySpendFilter,
  pageSize,
  setPage
}) => {
  const [sortField, setSortField] = useState<SortField>('savings');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
  const [exclusionsData, setExclusionsData] = useState<ExclusionEntry[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const userContext = useContext(UserContext) as UserContextType;
  const [showActiveOnly, setShowActiveOnly] = useState<boolean>(true);

  useEffect(() => {
    fetchExclusionsData();
  }, [dates, page, dutySpendFilter, pageSize]);

  const fetchExclusionsData = async () => {
    try {
      const startDate = dates?.startDate ?? "";
      const endDate = dates?.endDate ?? "";
      setLoading(true);

      const result = await axios.get(
        `${ENDPOINT}/dashboard-potential/savings/exclusions?dateFrom=${startDate}&dateTo=${endDate}&pageNo=${page}&pageSize=${pageSize}`,
        {
          headers: {
            authorization: `Bearer ${userContext?.user?.token}`,
          },
        }
      );

      if (result?.data?.data) {
        setExclusionsData(result.data.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error('Error fetching exclusions data:', err);
    }
  };

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('desc');
    }
  };

  const sortedData = [...exclusionsData].sort((a, b) => {
    const multiplier = sortOrder === 'asc' ? 1 : -1;
    
    switch (sortField) {
      case 'htsCode':
        return multiplier * a.htsCode.localeCompare(b.htsCode);
      case 'code99':
        return multiplier * a.code99.localeCompare(b.code99);
      case 'oldDutyRate':
        return multiplier * a.oldDutyRate.localeCompare(b.oldDutyRate);
      case 'newDutyRate':
        return multiplier * a.newDutyRate.localeCompare(b.newDutyRate);
      case 'savings':
        return multiplier * (a.savings - b.savings);
      case 'goodsValue':
        return multiplier * (a.goodsValue - b.goodsValue);
      default:
        return 0;
    }
  });

  const renderSortLabel = (field: SortField, label: string) => (
    <TableSortLabel
      active={sortField === field}
      direction={sortField === field ? sortOrder : 'desc'}
      onClick={() => handleSort(field)}
    >
      {label}
    </TableSortLabel>
  );

  const handleDownload = () => {
    // Convert data to CSV format
    const headers = ['HTS Code', '99 Code', 'Old Duty Rate', 'New Duty Rate', 'Potential Savings', 'Exclusion Description'];
    const csvData = sortedData.map(row => [
      row.htsCode,
      row.code99,
      row.oldDutyRate,
      row.newDutyRate,
      `$${row.savings.toLocaleString()}`,
      row.description
    ]);

    // Create CSV content
    const csvContent = [
      headers.join(','),
      ...csvData.map(row => row.map(cell => `"${cell}"`).join(','))
    ].join('\n');

    // Create and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', '301_exclusions_opportunities.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const filteredData = showActiveOnly 
    ? sortedData.filter(item => item.active)
    : sortedData;

  // Add a helper function similar to MTBTab's getStatusColor
  const getStatusColor = (active: boolean) => {
    return active ? 'success' : 'error';
  };

  const DescriptionCell = ({ text }: { text: string }) => {
    const [hasOverflow, setHasOverflow] = useState(false);
    const textRef = useRef<HTMLParagraphElement>(null);

    useEffect(() => {
      if (textRef.current) {
        const element = textRef.current;
        setHasOverflow(element.scrollHeight > element.clientHeight);
      }
    }, [text]);

    const Typography = styled('p')({
      maxWidth: '400px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',
      lineHeight: '1.2em',
      maxHeight: '3.6em',
      whiteSpace: 'normal',
      margin: 0,
      '&:hover': {
        cursor: hasOverflow ? 'pointer' : 'default',
        textDecoration: hasOverflow ? 'underline' : 'none'
      }
    });

    const content = (
      <Typography ref={textRef}>
        {text}
      </Typography>
    );

    return hasOverflow ? (
      <Tooltip title={text} placement="top-start">
        {content}
      </Tooltip>
    ) : content;
  };

  return (
    <Box sx={{ 
      height: 'calc(100vh - 200px)',
      p: 3,
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Paper sx={{ 
        width: '100%', 
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Box sx={{ 
          p: 2, 
          borderBottom: '1px solid rgba(224, 224, 224, 1)',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography variant="h6">
              301 Exclusion Opportunities
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={showActiveOnly}
                  onChange={(e) => setShowActiveOnly(e.target.checked)}
                />
              }
              label="Show Active Only"
            />
          </Box>
          {exclusionsData.length > 0 && (
            <Button
              variant="outlined"
              startIcon={<FileDownloadIcon />}
              onClick={handleDownload}
            >
              Download CSV
            </Button>
          )}
        </Box>
        
        {loading ? (
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            height: '100%' 
          }}>
            <CircularProgress />
          </Box>
        ) : exclusionsData.length === 0 ? (
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            height: '100%',
            flexDirection: 'column',
            gap: 2
          }}>
            <Typography variant="h6" color="text.secondary">
              No Opportunities Available
            </Typography>
            <Typography variant="body1" color="text.secondary">
              There are currently no exclusion opportunities that match your criteria.
            </Typography>
          </Box>
        ) : (
          <TableContainer sx={{ flexGrow: 1 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {renderSortLabel('htsCode', 'HTS Code')}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {renderSortLabel('code99', '99 Code')}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    Status
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {renderSortLabel('goodsValue', 'Goods Value')}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {renderSortLabel('oldDutyRate', 'Current Duty Rate')}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {renderSortLabel('newDutyRate', 'New Duty Rate')}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {renderSortLabel('savings', 'Potential Savings')}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    Description
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((row, index) => (
                  <TableRow 
                    key={index}
                    sx={{ '&:nth-of-type(odd)': { backgroundColor: 'rgba(0, 0, 0, 0.02)' } }}
                  >
                    <TableCell>{row.htsCode}</TableCell>
                    <TableCell>{row.code99}</TableCell>
                    <TableCell>
                      <Chip 
                        label={row.active ? 'Active' : 'Expired'}
                        color={getStatusColor(row.active)}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>${row.goodsValue.toLocaleString()}</TableCell>
                    <TableCell>{row.oldDutyRate}</TableCell>
                    <TableCell>{row.newDutyRate}</TableCell>
                    <TableCell sx={{ 
                      color: 'success.main',
                      fontWeight: 'bold'
                    }}>
                      ${row.savings.toLocaleString()}
                    </TableCell>
                    <TableCell>
                      <DescriptionCell text={row.description} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </Box>
  );
};

export default ExclusionsTab; 